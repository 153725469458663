(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/expandable-box/assets/javascripts/components/header.js') >= 0) return;  svs.modules.push('/components/components/expandable-box/assets/javascripts/components/header.js');
"use strict";

const _excluded = ["children", "className", "contentId", "onExpand", "isExpanded"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }

((svs, React, PropTypes) => {
  const {
    withContext
  } = svs.components.expandableBox;
  const Header = _ref => {
    let {
        children,
        className,
        contentId,
        onExpand,
        isExpanded
      } = _ref,
      props = _objectWithoutProperties(_ref, _excluded);
    const handleKeyDown = e => {
      if (e.keyCode === 13) {
        onExpand();
      }
    };
    return React.createElement("div", _extends({
      "aria-controls": contentId,
      "aria-expanded": isExpanded,
      className: className,
      onClick: onExpand,
      onKeyDown: handleKeyDown,
      role: "button",
      tabIndex: "0"
    }, props), children);
  };
  svs.components.expandableBox.Header = withContext(Header);
})(svs, React, PropTypes);

 })(window);