(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/expandable-box/assets/javascripts/components/expandable-box.js') >= 0) return;  svs.modules.push('/components/components/expandable-box/assets/javascripts/components/expandable-box.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

(function (React, PropTypes) {
  const {
    Provider
  } = svs.components.expandableBox.ExpandableBoxContext;
  class ExpandableBox extends React.PureComponent {
    constructor(props) {
      super(props);
      _defineProperty(this, "state", {
        isExpanded: this.props.isExpanded
      });
      this.toggleExpand = this.toggleExpand.bind(this);
      this.contentId = "expandable-box-".concat(Math.round(Math.random() * 100000 + 1000));
    }
    static getDerivedStateFromProps(_ref, state) {
      let {
        isExpanded
      } = _ref;
      return state.isAutonomous ? state : _objectSpread(_objectSpread({}, state), {}, {
        isExpanded
      });
    }
    toggleExpand() {
      this.setState({
        isExpanded: !this.state.isExpanded,
        isAutonomous: true
      });
    }
    render() {
      const {
        children
      } = this.props;
      return React.createElement(Provider, {
        value: {
          contentId: this.contentId,
          isExpanded: this.state.isExpanded,
          onExpand: this.toggleExpand
        }
      }, typeof children === 'function' ? children(this.state.isExpanded) : children);
    }
  }
  ExpandableBox.defaultProps = {
    isExpanded: false
  };
  svs.components.expandableBox.ExpandableBox = ExpandableBox;
})(React, PropTypes);

 })(window);