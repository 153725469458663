(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/expandable-box/assets/javascripts/components/expandable-box-context.js') >= 0) return;  svs.modules.push('/components/components/expandable-box/assets/javascripts/components/expandable-box-context.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }

((svs, React) => {
  const ExpandableBoxContext = React.createContext({
    contentId: null,
    onExpand: () => undefined,
    isExpanded: null
  });
  const {
    Consumer
  } = ExpandableBoxContext;
  const withContext = Component => props => React.createElement(Consumer, null, context => React.createElement(Component, _extends({}, props, context)));
  svs.components.expandableBox.ExpandableBoxContext = ExpandableBoxContext;
  svs.components.expandableBox.withContext = withContext;
})(svs, React);

 })(window);